.cdk-overlay-container{
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
}


.cdk-overlay-backdrop{
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0;
}

.cdk-overlay-connected-position-bounding-box{
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
    direction: ltr;
    unicode-bidi: isolate;
}

.cdk-overlay-pane{
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%;
}

.mat-select-panel{
    background:#fc7f38bf;
}

mat-option{
    border: solid #46dffc 1px;
}